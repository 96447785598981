<template>
    <div v-if="form">
        <a-select v-model="form.id" @change="updatedForm" :options="list" />
        <a-form-item style="margin-top: 14px; margin-bottom: 0;" label="Redirect on submit?">
            <a-switch v-model="form.redirect" />
        </a-form-item>
        <a-form-item style="margin-top: 14px; margin-bottom: 0;" v-if="form.redirect" label="Choose page to redirect to">
            <a-select v-model="form.page" :options="pagesOptions" />
        </a-form-item>
    </div>
</template>

<script>

import {arrToObj} from 'bh-mod'

export default {
    props:['value'],
    data:( () => ({
        form:{
            id:'',
            page:'',
			instance:'',
            redirect:false
        }
    })),
    watch:{
        form:{
            deep:true,
            handler(val){
                if (val && !val.redirect){
                    this.form.page = ''
                }
                this.$emit('input',val)
            }
        }
    },
    computed:{
        pagesOptions(){
            return this.$store.getters.pagesOptions
        },
        formSelected:{
            get(){

                if (this.form && this.form.id) return JSON.stringify(this.form)
                return ''

            },
            set(val){
                val = JSON.parse(val)
                console.log('LISTTTTTT', val)
                this.form = val || { id: '', page: '', instance: '', redirect: false }
            }
        },
        list(){
            let forms = Object.values(this.$store.state.appData.formList).map(x => ({
                label:x.name,
                value:x.id
            }))
            return forms
        }
    },
	methods:{
		updatedForm(){
			this.form.instance = this.$store.state.instance.id
			console.log('UPDATED FORM', this.form)
		}
	},
    created(){
        if (!Object.keys(this.list).length){
            this.$api.get('/forms/:instance').then( ({data}) => {
                data = arrToObj(data)
                this.$store.commit('SET_PROP',{where:['formList'],what:data})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
		this.form = this.value || { id: '', page: '', instance: '', redirect: false }
    }
}
</script>

<style>

</style>
